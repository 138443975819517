import React, { lazy, useEffect } from 'react';

import cuid from 'cuid';
import { FullStoryAPI } from 'react-fullstory';
import { Navigate, Route, Routes } from 'react-router';

import AccountRouter from 'account/components/AccountRouter';
import account from 'account/ducks/account';
import { Environments, UserRole } from 'contracts/enums';
import { environment } from 'core/services/environment';
import { logEvent } from 'core/services/logging';
import MarketRouter from 'market/components/MarketRouter';
import QuoteRouter from 'quote/components/QuoteRouter';
import VendorRouter from 'vendor/components/VendorRouter';

import AuthRoute from './AuthRoute';
import LazyRoute from './LazyRoute';
import RoleRoute from './RoleRoute';
import { AccountRoutes, DashboardRoutes, MarketRoutes, QuoteRoutes, ServiceRoutes, UsersRoutes, VendorRoutes } from './Routes';

const CustomVendorCostAuthResolver = lazy(() => import('vendor/components/pages/CustomVendorCostAuthResolver'));
const DashboardPage = lazy(() => import('dashboard/components/pages/DashboardPage'));
const ProposalPage = lazy(() => import('sale/components/pages/ProposalPage'));
const SalePage = lazy(() => import('sale/components/pages/SalePage'));
const UsersPage = lazy(() => import('users/components/pages/UsersPage'));

const MainRouter: React.FC = () => {
  const userDetails = account.selectors.getCurrentUser();

  useEffect(() => {
    let sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      const uniqueId = cuid();
      sessionStorage.setItem('sessionId', uniqueId);
      sessionId = uniqueId;
    }
  }, []);
  
  useEffect(() => {
    try {
      const sessionId = sessionStorage.getItem('sessionId');
      if (userDetails && environment !== Environments.local) {
        FullStoryAPI('identify', userDetails.email, {
          email: userDetails.email,
          uniqueId: sessionId as string,
        });
      }
    } catch (err) {
      logEvent('ROUTING_EVENT_ERROR', { err });
    }
  }, [userDetails]);

  return (
    <Routes>
      <Route path={`${AccountRoutes.root}/*`} element={<AccountRouter/>} />
      <Route path={VendorRoutes.authorize} element={<LazyRoute><CustomVendorCostAuthResolver/></LazyRoute>} />
      <Route element={<AuthRoute />} >
        <Route path={`${MarketRoutes.root}/*`} element={<MarketRouter/>} />
        <Route path={`${VendorRoutes.root}/*`} element={<VendorRouter/>} />
        <Route path={`${QuoteRoutes.root}/*`} element={
          <RoleRoute
            roles={[
              UserRole.admin,
              UserRole.configurationUser,
              UserRole.manager,
              UserRole.salesPerson,
              UserRole.bulkPricing,
              UserRole.bulkPricingDetailedView,
              UserRole.rateUploadUser]}
            redirectTo={VendorRoutes.root + VendorRoutes.serviceRates}>
            <QuoteRouter />
          </RoleRoute>
        } />
        <Route path={DashboardRoutes.root} element={
          <RoleRoute
            roles={[UserRole.admin, UserRole.configurationUser, UserRole.manager, UserRole.salesPerson]}
            redirectTo={VendorRoutes.root + VendorRoutes.serviceRates}>
            <DashboardPage />
          </RoleRoute>
        } />
        <Route path={ServiceRoutes.sale} element={
          <RoleRoute 
            roles={[UserRole.admin, UserRole.configurationUser, UserRole.manager, UserRole.salesPerson]}
            redirectTo={VendorRoutes.root + VendorRoutes.serviceRates}>
            <SalePage />
          </RoleRoute>
        } />
        <Route path={ServiceRoutes.proposal} element={
          <RoleRoute 
            roles={[UserRole.admin, UserRole.configurationUser, UserRole.manager, UserRole.salesPerson]}
            redirectTo={DashboardRoutes.root}>
            <ProposalPage />
          </RoleRoute>
        } />
        <Route path={ServiceRoutes.proposalEdit} element={
          <RoleRoute 
            roles={[UserRole.admin, UserRole.configurationUser, UserRole.manager, UserRole.salesPerson]}
            redirectTo={DashboardRoutes.root}>
            <ProposalPage />
          </RoleRoute>
        } />
        <Route path={UsersRoutes.root} element={
          <RoleRoute 
            roles={[UserRole.admin]}
            redirectTo={VendorRoutes.root + VendorRoutes.serviceRates}>
            <UsersPage />
          </RoleRoute>
        } />
      </Route>
      {/* Default Route */}
      <Route path="*" element={<Navigate to={DashboardRoutes.root} replace />} />
      <Route index element={<Navigate to={DashboardRoutes.root} replace/>} />
    </Routes>
  );
};

export default MainRouter;
